const modal = document.getElementById('modal-services');
const openModalButton = document.getElementById('open-modal');
const closeModalButton = document.querySelector('.close-modal');


openModalButton.addEventListener('click', () => {
    modal.classList.remove('hide');
    modal.classList.add('show');
});


closeModalButton.addEventListener('click', () => {
    closeModal();
});


window.addEventListener('click', (event) => {
    if (event.target === modal) {
        closeModal();
    }
});


function closeModal() {
    modal.classList.remove('show');
    modal.classList.add('hide');
}
